import http from './http';
import { type httpResponse ,type Login} from './http_types';

import * as c from 'crypto-js';

// const cryptoJS = require('crypto-js');

// const MD5 = cryptoJS.MD5('your message');

function login(sl: Login) {
  // md5加密与username password
  sl.password = c.MD5(sl.password + sl.username).toString();
  return http.post(`/user/login`, sl) as Promise<httpResponse>;
}

export default {
  login,
};

